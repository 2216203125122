import { Component, Inject, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlatformConfigurationService } from 'src/app/services/platform-configuration.service';

@Component({
    templateUrl: './simple-input-dialog.component.html',
})
export class SimpleInputDialog implements OnInit {
    platformConfigurationService = inject(PlatformConfigurationService);

    inputType = this.data.isNumber ? 'number' : 'text';
    form = new FormGroup({ input: new FormControl() });

    get disabled() {
        const value = this.form.get('input').value;
        return typeof value == 'string' && value.trim() == '';
    }

    constructor(
        public dialogRef: MatDialogRef<SimpleInputDialog>,
        @Inject(MAT_DIALOG_DATA) public data,
    ) {}

    ngOnInit() {
        this.form.get('input').setValue(this.data.input ?? '');
    }

    handleSave() {
        this.dialogRef.close(this.form.get('input').value);
    }
}
